import { Container, Section } from '@components/common'
import ContactForm from '@components/ContactForm'
import Head from '@components/Head'
import Card from '@mrs-ui/Card'
import React from 'react'

type Item = {
  label: string
  path?: string
}

const contactMenuItems: Array<Item> = [
  {
    label: 'info@mrscompany.com',
    path: 'mailto:info@mrscompany.com',
  },
  {
    label: '+1 (416)-620-2720',
    path: 'tel:4165570110',
  },
  {
    label: '305-75 International Blvd.',
  },
  {
    label: 'Etobicoke, ON, Canada',
  },
  {
    label: 'M9W 6L9',
  },
]

const ContactPage = () => {
  return (
    <>
      <Head
        title="Contact MRS"
        description="Contact MRS Company to learn about our services and expertise in digital technology."
      />
      <section className="py-gap-compact">
        <Container>
          <div className="flex flex-wrap">
            <div className="w-full md:w-3/5 md:pr-gap-xl">
              <h2 className="mb-4">Contact MRS</h2>
              <p className="mb-8">
                Fill out the form below and we'll be with you in a hurry.
              </p>
              <ContactForm autofocus />
            </div>
            <div className="w-full md:w-2/5 mt-gap-mini md:mt-0">
              <Card className="p-gap-mini">
                <h3 className="mb-4">Contact Information</h3>
                <ul className="list-none p-0">
                  {contactMenuItems.map((i: Item, index) => (
                    <li
                      key={i.label}
                      className={`block pb-1 ${index === 1 ? 'mb-4' : ''}`}
                    >
                      <a
                        href={i.path}
                        target="_blank"
                        className="text-small text-shade700 link-transition hover:text-primary"
                      >
                        {i.label}
                      </a>
                    </li>
                  ))}
                </ul>
              </Card>
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}
export default ContactPage
